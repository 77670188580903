import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ExternalURLsConfig, URLsConfig } from './interceptor.model';
import { Observable } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import { StorageService } from '@core/services/storage.service';
import { DateTimeFnsService } from '../services/date-time-fns.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private datetimeFnsService: DateTimeFnsService,
    private storageService: StorageService
  ) {}

  static getBackendHeaders(url: string): { headers: { [key: string]: any }; overwriteHeaders: boolean } {
    let headers = {};
    let overwriteHeaders = false;
    for (let i = 0; i < URLsConfig.length; i++) {
      const urlConfig = URLsConfig[i];
      if (urlConfig.urlPattern.test(url)) {
        headers = urlConfig.headers;
        overwriteHeaders = urlConfig.overwriteHeaders;
        break;
      }
    }
    return { headers, overwriteHeaders };
  }

  static isRequestToExternalSource(url: string): boolean {
    const externalList = ExternalURLsConfig;
    let isExternal = false;
    for (let i = 0; i < externalList.length; i++) {
      if (url.includes(externalList[i].url) && url.includes(externalList[i].path)) {
        isExternal = true;
        return isExternal;
      }
    }

    return isExternal;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestUrl = request.url;
    if (HttpConfigInterceptor.isRequestToExternalSource(requestUrl)) {
      return next.handle(request);
    } else {
      const token: string = this.authService.adpp_jwt_token;
      const lang: string = this.storageService.storage.getItem('lang');
      // add required headers first;
      if (token) {
        request = request.clone({ headers: request.headers.set('Authorization', `JWT ${token}`) });
      }
      request = request.clone({ headers: request.headers.set('Accept-Language', lang) });
      if (this.datetimeFnsService.currentTimeZone) {
        request = request.clone({ headers: request.headers.set('AM-timezone', this.datetimeFnsService.currentTimeZone) });
      }
      if (this.datetimeFnsService.timezoneOffsetInHours) {
        request = request.clone({ headers: request.headers.set('AM-utc-offset', `${this.datetimeFnsService.timezoneOffsetInHours}`) });
      }
      const urlHeadersConfig = HttpConfigInterceptor.getBackendHeaders(request.url);
      if (!urlHeadersConfig?.overwriteHeaders) {
        const headerKeys = Object.keys(urlHeadersConfig.headers);
        headerKeys.forEach((key) => {
          request = request.clone({ headers: request.headers.set(key, urlHeadersConfig.headers[key]) });
        });
      } else {
        request = request.clone({ headers: new HttpHeaders(urlHeadersConfig.headers) });
      }

      return next.handle(request);
    }
  }
}
