import { inject } from '@angular/core';
import { InMemoryCache } from '@apollo/client/core';
import { environment } from '@src/environments/environment';
import { HttpLink } from 'apollo-angular/http';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';

const uri = environment.gql;
const uri_v2 = `${environment.gql}/v2`;

export const apolloSettings = () => {
  const httpLink = inject(HttpLink);

  return {
    default: {
      link: httpLink.create({
        uri,
        extractFiles: (body) => extractFiles(body, isExtractableFile),
      }),
      cache: new InMemoryCache({ addTypename: false }),
    },
    v2: {
      link: httpLink.create({
        uri: uri_v2,
        extractFiles: (body) => extractFiles(body, isExtractableFile),
      }),
      cache: new InMemoryCache({
        addTypename: false,
      }),
    },
  };
};
