import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanActivateGuard, PermissionGuard } from '@core/auth/app';
import { RouterPermissions, ScreenPermissions } from '@core/models/router-permissions.model';

const routes: Routes = [
  {
    path: 'apps/dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.all,
      screenId: ScreenPermissions.dashboard,
    },
  },
  {
    path: 'apps/report',
    loadChildren: () => import('./report/report.module').then((m) => m.ReportModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.all,
      screenId: ScreenPermissions.report,
    },
  },
  {
    path: 'apps/payment',
    loadChildren: () => import('./payment/payment.module').then((m) => m.PaymentModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.all,
      screenId: ScreenPermissions.payment,
    },
  },
  {
    path: 'apps/account',
    loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.adminAndPublisherAdmin,
      screenId: ScreenPermissions.accounts,
    },
  },
  {
    path: 'apps/site',
    loadChildren: () => import('./site/site.module').then((m) => m.SiteModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.all,
      screenId: ScreenPermissions.sites,
    },
  },
  {
    path: 'apps/apps',
    loadChildren: () => import('./apps/apps.module').then((m) => m.AppsModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.all,
      screenId: ScreenPermissions.apps,
    },
  },
  {
    path: 'apps/ad-unit',
    loadChildren: () => import('./ad-unit/ad-unit.module').then((m) => m.AdUnitModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.adminAndPublisherAdmin,
      screenId: ScreenPermissions.adUnits,
    },
  },
  {
    path: 'apps/third-party-tag',
    loadChildren: () => import('./third-party-tag/third-party-tag.module').then((m) => m.ThirdPartyTagModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.all,
      screenId: ScreenPermissions.mapping,
    },
  },
  {
    path: 'apps/import',
    loadChildren: () => import('./import/import.module').then((m) => m.ImportModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.adminAndPublisherAdmin,
      screenId: ScreenPermissions.import,
    },
  },
  {
    path: 'apps/user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.adminAndPublisherAdmin,
      screenId: ScreenPermissions.import,
    },
  },
  {
    path: 'apps/setting',
    loadChildren: () => import('./setting/setting.module').then((m) => m.SettingModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: [...RouterPermissions.all, ...RouterPermissions.game],
      screenId: ScreenPermissions.setting,
    },
  },
  {
    path: 'apps/integration',
    loadChildren: () => import('./integration/integration.module').then((m) => m.IntegrationModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.adminAndPublisherAdmin,
      screenId: ScreenPermissions.integration,
    },
  },
  {
    path: 'apps/change-history',
    loadChildren: () => import('./change-history/change-history.module').then((m) => m.ChangeHistoryModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.all,
      screenId: ScreenPermissions.changeHistory,
    },
  },
  {
    path: 'apps/ats',
    loadChildren: () => import('./ats/ats.module').then((m) => m.AtsModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.all,
      screenId: ScreenPermissions.sites,
    },
  },
  {
    path: 'apps/amp',
    loadChildren: () => import('./amp/amp.module').then((m) => m.AmpModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.all,
      screenId: ScreenPermissions.sites,
    },
  },
  {
    path: 'apps/aso',
    loadChildren: () => import('./aso/aso.module').then((m) => m.AsoModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.all,
      screenId: ScreenPermissions.aso,
    },
  },
  {
    path: 'apps/pwa',
    loadChildren: () => import('./pwa/pwa.module').then((m) => m.PWAModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.all,
      screenId: ScreenPermissions.sites,
    },
  },
  {
    path: 'apps/social-media',
    loadChildren: () => import('./sns/sns.module').then((m) => m.SnsModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.all,
      screenId: ScreenPermissions.socialMedia,
    },
  },
  {
    path: 'apps/story-engine',
    loadChildren: () => import('./web-stories/web-stories.module').then((m) => m.WebStoriesModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.all,
      screenId: ScreenPermissions.sites,
    },
  },
  {
    path: 'apps/shopify',
    loadChildren: () => import('./shopify/shopify.module').then((m) => m.ShopifyModule),
  },
  {
    path: 'apps/cwv',
    loadChildren: () => import('./cwv-analytics/cwv-analytics.module').then((m) => m.CwvAnalyticsModule),
  },
  {
    path: 'apps/help',
    loadChildren: () => import('./help-page/help-page.module').then((m) => m.HelpPageModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.all,
      screenId: ScreenPermissions.all,
    },
  },
  {
    path: 'apps/upr',
    loadChildren: () => import('./upr-settings/upr-settings.module').then((m) => m.UprSettingsModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.adminOnly,
      screenId: ScreenPermissions.all,
    },
  },
  {
    path: 'apps/ads-txt',
    loadChildren: () => import('./ads-txt/ads-txt.module').then((m) => m.AdsTxtModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.all,
      screenId: ScreenPermissions.all,
    },
  },
  {
    path: 'apps/invoice',
    loadChildren: () => import('./invoice/invoice.module').then((m) => m.InvoiceModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.adminAndPublisherAdmin,
      screenId: ScreenPermissions.invoice,
    },
  },
  {
    path: 'apps/margin',
    loadChildren: () => import('./margin/margin.module').then((m) => m.MarginModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.adminAndPublisherAdmin,
      screenId: ScreenPermissions.accounts,
    },
  },
  {
    path: 'apps/game',
    loadChildren: () => import('./html-game/html-game.module').then((m) => m.HtmlGameModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.all,
      screenId: ScreenPermissions.all,
    },
  },
  {
    path: 'apps/game-develop',
    loadChildren: () => import('./html-game-develop/game-develop.module').then((m) => m.GameDevelopModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: [...RouterPermissions.all, ...RouterPermissions.game],
      screenId: ScreenPermissions.all,
    },
  },
  {
    path: 'apps/exchange-rate',
    loadChildren: () => import('./exchange-rate/exchange-rate.module').then((m) => m.ExchangeRateModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.adminOnly,
      screenId: ScreenPermissions.all,
    },
  },
  {
    path: 'apps/dmp',
    loadChildren: () => import('./dmp/dmp.module').then((m) => m.DmpModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.all,
      screenId: ScreenPermissions.all,
    },
  },
  {
    path: 'apps/admob-mediation',
    loadChildren: () => import('./admob-mediation/admob-mediation.module').then((m) => m.AdmobMediationModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.adminOnly,
      screenId: ScreenPermissions.all,
    },
  },
  {
    path: 'apps/vcpm-optimizer',
    loadChildren: () => import('./vcpm-optimizer/vcpm-optimizer.module').then((m) => m.VcpmOptimizerModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.adminOnly,
      screenId: ScreenPermissions.all,
    },
  },
  {
    path: 'apps/video-generator',
    loadChildren: () => import('./video-generator/video-generator.module').then((m) => m.VideoGeneratorModule),
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.all,
      screenId: ScreenPermissions.all,
    },
  },
  {
    path: 'apps/ecpm-optimizer',
    loadChildren: () => import('ecpm-optimizer').then((m) => m.ecpmOptimizerRoutes),
    data: {
      scopes: RouterPermissions.all,
    },
  },
  {
    path: 'apps/ai-assistant',
    loadChildren: () => import('./ai-chatbot/ai-chatbot-routing.module').then((m) => m.AIAssistantRoutes),
    // children: AIAssistantRoutes,
    canActivate: [CanActivateGuard, PermissionGuard],
    data: {
      scopes: RouterPermissions.adminAndPublisherAdmin,
      screenId: ScreenPermissions.all,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {}
